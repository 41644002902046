body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
button,
#root,
.App {
    height: 100%;
    font-family: Helvetica;
    font-weight: 700;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.alert-enter {
    opacity: 0;
    margin-top: 10px;
}

.alert-enter-active {
    opacity: 1;
    margin-top: 0px;
    transition: opacity 300ms, margin-top 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.gameResultAlert-enter {
    opacity: 0;
    /* transform: scale(0.9); */
    margin-top: 30px;
}

.gameResultAlert-enter-active {
    opacity: 1;
    -webkit-transform: translateX(0);
            transform: translateX(0);
    margin-top: 0px;
    transition: opacity 300ms, margin-top 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms, margin-top 300ms;
    transition: opacity 300ms, transform 300ms, margin-top 300ms, -webkit-transform 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.gameResultAlert-exit {
    opacity: 1;
}

.gameResultAlert-exit-active {
    opacity: 0;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    transition: opacity 300ms, -webkit-transform 300ms;
    transition: opacity 300ms, transform 300ms;
    transition: opacity 300ms, transform 300ms, -webkit-transform 300ms;
}

.menu-enter {
    opacity: 0;
    /* margin-top: 10px; */
}

.menu-enter-active {
    opacity: 1;
    margin-top: 0px;
    transition: opacity 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
}

.menu-exit {
    opacity: 1;
}

.menu-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}


/* Key animations */

@-webkit-keyframes keyBounceEnter {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@keyframes keyBounceEnter {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@-webkit-keyframes keyBounce {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@keyframes keyBounce {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@-webkit-keyframes keyBounceWord {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1
    }
}

@keyframes keyBounceWord {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1
    }
}

@-webkit-keyframes keyBounceMiss {
    0% {
        scale: 1;
    }
    25% {
        scale: 0.75
    }
    100% {
        scale: 1
    }
}

@keyframes keyBounceMiss {
    0% {
        scale: 1;
    }
    25% {
        scale: 0.75
    }
    100% {
        scale: 1
    }
}

@-webkit-keyframes whiffBounce {
    0% {
        -webkit-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0);
    }
    25% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0);
    }
}

@keyframes whiffBounce {
    0% {
        -webkit-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0);
    }
    25% {
        -webkit-transform: translate3d(10px, 0, 0);
                transform: translate3d(10px, 0, 0);
    }
    50% {
        -webkit-transform: translate3d(-10px, 0, 0);
                transform: translate3d(-10px, 0, 0);
    }
    75% {
        -webkit-transform: translate3d(5px, 0, 0);
                transform: translate3d(5px, 0, 0);
    }
    100% {
        -webkit-transform: translate3d(0px, 0, 0);
                transform: translate3d(0px, 0, 0);
    }
}

@-webkit-keyframes lastWhiff {
    0% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
    50% {
        /* transform: scale(1.1); */
        border-color: #CE5D5D;
    }
    100% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
}

@keyframes lastWhiff {
    0% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
    50% {
        /* transform: scale(1.1); */
        border-color: #CE5D5D;
    }
    100% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
}

@-webkit-keyframes homePageEnter {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-175px);
                transform: translateY(-175px);
        opacity: 0;
    }
}

@keyframes homePageEnter {
    0% {
        -webkit-transform: translateY(0);
                transform: translateY(0);
        opacity: 1;
    }
    40% {
        -webkit-transform: translateY(15px);
                transform: translateY(15px);
        opacity: 1;
    }
    100% {
        -webkit-transform: translateY(-175px);
                transform: translateY(-175px);
        opacity: 0;
    }
}
