.alert-enter {
    opacity: 0;
    margin-top: 10px;
}

.alert-enter-active {
    opacity: 1;
    margin-top: 0px;
    transition: opacity 300ms, margin-top 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    animation-fill-mode: forwards;
}

.alert-exit {
    opacity: 1;
}

.alert-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}

.gameResultAlert-enter {
    opacity: 0;
    /* transform: scale(0.9); */
    margin-top: 30px;
}

.gameResultAlert-enter-active {
    opacity: 1;
    transform: translateX(0);
    margin-top: 0px;
    transition: opacity 300ms, transform 300ms, margin-top 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    animation-fill-mode: forwards;
}

.gameResultAlert-exit {
    opacity: 1;
}

.gameResultAlert-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}

.menu-enter {
    opacity: 0;
    /* margin-top: 10px; */
}

.menu-enter-active {
    opacity: 1;
    margin-top: 0px;
    transition: opacity 300ms;
    transition-timing-function: cubic-bezier(.04, .58, .32, 1);
    animation-fill-mode: forwards;
}

.menu-exit {
    opacity: 1;
}

.menu-exit-active {
    opacity: 0;
    transition: opacity 300ms;
}


/* Key animations */

@keyframes keyBounceEnter {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@keyframes keyBounce {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1;
    }
}

@keyframes keyBounceWord {
    0% {
        scale: 1;
    }
    25% {
        scale: 1.2;
    }
    100% {
        scale: 1
    }
}

@keyframes keyBounceMiss {
    0% {
        scale: 1;
    }
    25% {
        scale: 0.75
    }
    100% {
        scale: 1
    }
}

@keyframes whiffBounce {
    0% {
        transform: translate3d(0px, 0, 0);
    }
    25% {
        transform: translate3d(10px, 0, 0);
    }
    50% {
        transform: translate3d(-10px, 0, 0);
    }
    75% {
        transform: translate3d(5px, 0, 0);
    }
    100% {
        transform: translate3d(0px, 0, 0);
    }
}

@keyframes lastWhiff {
    0% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
    50% {
        /* transform: scale(1.1); */
        border-color: #CE5D5D;
    }
    100% {
        /* transform: scale(1); */
        border-color: #CFD1D5;
    }
}

@keyframes homePageEnter {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    40% {
        transform: translateY(15px);
        opacity: 1;
    }
    100% {
        transform: translateY(-175px);
        opacity: 0;
    }
}